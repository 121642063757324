<template>
  <!-- Add Driver Modal -->
  <b-modal
    id="modalCreditRequest"
    lazy
    no-fade
    size="lg"
    no-enforce-focus
    :title="$t('payments.creditRequest.modalTitle')"
    :cancel-title="$t('payments.buttons.close')"
    v-model="cpHandlerModal"
    @show="handleOpen"
    @hidden="handleClose"
  >
    <b-form name="driver" v-if="modal_data.id">
      <h5 class="d-block pt-1 pb-2">
        {{ $t('payments.creditRequest.modalDriverDetailsTitle') }}
      </h5>
      <b-form-row>
        <b-form-group
          id="fg-driver-name"
          :label="$t('payments.creditRequest.labelDriverName')"
          label-for="driver-name"
          required
          class="col-md-6 col-sm-12"
        >
          <b-form-input
            id="driver-name"
            disabled
            :value="modal_data.driver.name"
            type="text"
          />
        </b-form-group>

        <b-form-group
          id="fg-driver-name"
          label-for="driver-cpf"
          required
          class="col-md-6 col-sm-12"
        >
          <b-link
            :href="`/drivers/profile/${modal_data.driver.id}`"
            target="_blank"
            style="margin-top: 24px"
            class="btn btn-primary"
            :title="$t('payments.labels.goToDriver')"
          >
            <i class="fa fa-external-link" style="width: 12px; text-align: center" />
            {{ $t('payments.labels.goToDriver') }}
          </b-link>
        </b-form-group>
      </b-form-row>
      <hr>
      <h5 class="d-block pt-1 pb-2">
        {{ $t('payments.creditRequest.modalCreditRequestTitle') }}
      </h5>
      <b-form-row>

        <b-form-group
          id="fg-credit-value"
          :label="$t('payments.creditRequest.labelCreditValue')"
          label-for="credit-value"
          class="col-md-4 col-sm-12"
        >
          <b-form-input
            id="credit-value"
            disabled
            :value="setPriceFormat($t('payments.labels.currency'), modal_data.amount_operation)"
            type="text"
          />
        </b-form-group>        
      </b-form-row>

      <b-form-row>
        <b-form-group
          id="fg-credit-requester"
          :label="$t('payments.creditRequest.labelCreditRequester')"
          label-for="credit-requester"
          class="col-md-5 col-sm-12"
        >
          <b-form-input
            id="credit-requester"
            disabled
            :value="modal_data.requested_by"
            type="text"
          />
        </b-form-group>

        <b-form-group
          id="fg-credit-created-at"
          :label="$t('payments.creditRequest.labelCreditCreatedAt')"
          label-for="credit-created-at"
          class="col-md-4 col-sm-12"
        >
          <b-form-input
            id="credit-requester"
            disabled
              :value="modal_data.created_at | moment('DD/MM/YYYY')"
            type="text"
          />
        </b-form-group>

        <b-form-group
          id="fg-driver-invoice"
          :label="$t('payments.creditRequest.labelDriverInvoice')"
          label-for="driver-invoice"
          required
          class="col-md-3 col-sm-12"
        >
          <b-link
            :href="modal_data.invoice"
            target="_blank"
            class="btn btn-primary"
            :title="$t('payments.creditRequest.labelOpenInvoice')"
          >
            <i class="fa fa-external-link" style="width: 12px; text-align: center" />
            {{ $t('payments.buttons.goToInvoice') }}
          </b-link>

        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group
          id="fg-credit-request-reason"
          :label="$t('payments.creditRequest.labelCreditReason')"
          label-for="credit-reason"
          class="col-md-12 col-sm-12"
        >
          <b-form-textarea
            id="credit-reason"
            disabled
            rows="3"
            resize="none"
            :value="modal_data.reason"
            type="text"
          />
        </b-form-group>
      </b-form-row>
    </b-form>

    <div slot="modal-footer">
      <b-button
      variant="light"
      @click.stop="handleClose"
    >
      {{ $t('payments.buttons.cancel') }}
    </b-button>

      <b-button
      variant="danger"
      class="ml-2"
      @click.stop="$emit('reprove', modal_data.id)"
      v-b-tooltip.hover :title="$t('payments.buttons.reproveCredit')"
    >
      {{ $t('payments.buttons.reproveCredit') }}
    </b-button>

    <b-button
      variant="success"
      class="ml-2"
      @click.stop="$emit('approve', modal_data.id)"
      v-b-tooltip.hover :title="$t('payments.buttons.approveCredit')"
    >
      {{ $t('payments.buttons.approveCredit') }}
    </b-button>
    </div>
  </b-modal>
</template>

<script>
import { formatPrice } from '@utils/numbers';

export default {
  name: 'ModalUpdateCreditRequest',
  props: {
    modal_data: {
      type: Object,
      required: true,
      default: null
    },
    handlerModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      modalController: false,
      data: {
      },
    };
  },
  methods: {
    setPriceFormat(_currency, _value) {
      return formatPrice(_currency, _value);
    },
    handleClose() {
      this.$emit('change', false);
    },
    handleOpen() {
      this.data = this.modal_data.data
    },
  },
  computed: {
    cpHandlerModal: {
      get() {
        return this.handlerModal;
      },
      set(value) {
        this.modalController = value;
        this.$emit('change', Boolean(value));
      },
    },
  },
  model: {
    prop: 'handlerModal',
    event: 'change',
  },
};
</script>

<style lang="scss" scoped></style>
