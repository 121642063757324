<template>
  <div>
    <loader v-if="loader" />
    <b-row class="pb-4">
      <b-col cols="12">
        <h2>
          {{ this.$route.meta.title }} <b-badge variant="primary" v-if="count > 0">{{ count }}</b-badge>
        </h2>
      </b-col>
    </b-row>

    <div class="table-pending-credit-request-list">
      <custom-data-table
        ref="pending_credit_table"
        :fields="fields"
        :filters="filters"
        :query="pending_credit_requests_query"
        :query-variables="{ kind: 'CREDIT_APPLYED', status: 'PENDING' }"
        :query-node="'getTransactionOperationsByKindAndStatus'"
        @vuetable:row-clicked="goTo"
      >
        <span slot="driver" slot-scope="props"> <router-link v-b-tooltip :title="$t('payments.labels.goToDriver')" :to="`/drivers/profile/${props.rowData.driver.id}`">{{ props.rowData.driver.name }} <i class="fa fa-external-link" style="width: 12px; text-align: center" /></router-link></span>
        <span slot="balance" slot-scope="props">{{ setPriceFormat($t('payments.labels.currency'), props.rowData.balance.balance_amount) }}</span>
        <span slot="credit_reason" slot-scope="props">{{$t(`transaction.creditReason.${props.rowData.credit_reason}`) }}</span>
        <span slot="invoice" slot-scope="props">
          <b-link
            :href="kovi_fatura + props.rowData.invoice"
            target="_blank"
            :title="$t('payments.creditRequest.labelOpenInvoice')"
            @click.stop
          >
            <i class="fa fa-external-link" style="width: 12px; text-align: center" />
            {{ $t('payments.creditRequest.goToInvoice') }}
          </b-link>
        </span>
        <span slot="amount_operation" slot-scope="props"> {{ setPriceFormat($t('payments.labels.currency'), props.rowData.amount_operation) }} </span>

        <span slot="created_at" slot-scope="props"> {{ formatDateUtc(props.rowData.created_at) | moment('from', true) }} </span>
      </custom-data-table>
    <modal-update-credit-request
      :modal_data="modalData"
      v-model="modalCreditRequest"
      @approve="approveCredit"
      @reprove="reproveCredit"
    >
    </modal-update-credit-request>
  </div>
  </div>
</template>

<script>
import { formatPrice } from '@utils/numbers';
import CustomDataTable from '@components/CustomDataTable';
import TRANSACTION_OPERATIONS_BY_KIND_STATUS from '@graphql/transaction-operations/queries/list-by-kind-and-status.gql';
import APPROVE_CREDIT from '@graphql/transaction-operations/mutations/approve-credit-request.gql';
import REPROVE_CREDIT from '@graphql/transaction-operations/mutations/reprove-credit-request.gql';
import { mapGetters } from 'vuex'
import ModalUpdateCreditRequest from '@components/modals/update-credit-request'
import { makeQuery, makeMutation } from "@graphql/middleware";
import PENDING_COUNT from '@graphql/transaction-operations/queries/count-pending.gql';

export default {
  name: 'CreditRequestsByStatus',
  components: {
    CustomDataTable,
    ModalUpdateCreditRequest,
    Loader: () => import('@components/shared/loader')
  },
  props: {
    fieldsAllow: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      modalData: {},
      count: 0,
      loader: false,
      modalCreditRequest: false,
      kovi_fatura: process.env.KOVI_FATURA_URL,
      pending_credit_requests_query: TRANSACTION_OPERATIONS_BY_KIND_STATUS,
      status: 'ACTIVE',
      fields: [{
          name: 'driver',
          title: this.$t('payments.creditRequest.tableDriverFieldTitle')
        },
        {
          name: 'balance',
          title: this.$t('payments.creditRequest.tableBalanceFieldTitle'),
        },
        {
          name: 'credit_reason',
          title: this.$t('payments.creditRequest.tableReasonFieldTitle'),
        },
        {
          name: 'amount_operation',
          title: this.$t('payments.creditRequest.tableAmountFieldTitle'),
        },
        {
          name: 'requested_by',
          title: this.$t('payments.creditRequest.tableRequesterFieldTitle'),
        },
        {
          name: 'created_at',
          title: this.$t('payments.creditRequest.tableCreatedAtFieldTitle'),
          sortField: 'created_at'
        }
      ],
      pagination: {
        current: 1,
        total: 10,
        order: 'DESC',
      },
    }
  },
  computed: {
    ...mapGetters('user', ['attributes']),
    filters() {
      return {}
    },
  },
  mounted() {
    this.getPendingCount()
  },
  methods: {
    formatDateUtc (date) {
      return this.$moment.utc(date).format('YYYY-MM-DDTHH:mm:ss')
    },
    async getPendingCount() {

      const result = await makeQuery(
        PENDING_COUNT,
        { kind: 'CREDIT_APPLYED', status: 'PENDING' }
      );
      this.count = result?.data?.getTransactionOperationsByKindAndStatus?.count
    },
    getTransactionOperatonErrorMsg (status_code) {
      return {
        403: this.$t('payments.creditRequest.error403Text'),
        404: this.$t('payments.creditRequest.error404Text'),
        422: this.$t('payments.creditRequest.error422Text'),
        500: this.$t('payments.creditRequest.error500Text')
      }[status_code];
    },
    showErrorModal(status_code) {
      this.$swal({
        type: 'error',
        title: this.$t('payments.creditRequest.modalErrorTitle'),
        text: (status_code === null) ? this.$t('payments.creditRequest.modalErrorText') : this.getTransactionOperatonErrorMsg(status_code),
        showConfirmButton: false,
        timer: 2500,
      }).then(() => console.log(true));
    },

    showSuccessModal() {
      this.$swal({
        type: 'success',
        title: this.$t('payments.creditRequest.modalSuccessTitle'),
        text: this.$t('payments.creditRequest.modalSuccessText'),
        showConfirmButton: false,
        timer: 2500,
      }).then(() => console.log(true));
    },

    approveCredit(id) {
      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      });

      confirmationWindow({
        title: this.$t('payments.creditRequest.modalConfirmationTitle'),
        text: this.$t('payments.creditRequest.modalConfirmationText'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('payments.buttons.confirm'),
        cancelButtonText: this.$t('payments.buttons.cancel'),
        showLoaderOnConfirm: true,
        reverseButtons: true,
      }).then(result => {
        if (result.value) {
          this.loader = true

          makeMutation(
            APPROVE_CREDIT,
            { id, approved_by: this.attributes.email }
          )
          .then((data) => {
              this.loader = false
              const response = data.data.approveCreditRequest
              if(response.status_code !== 200) throw response
              this.modalCreditRequest = false
              this.showSuccessModal();
              this.$refs.pending_credit_table.$_refresh();
              this.getPendingCount();
            })
            .catch((error) => {
              this.loader = false
              const status_code = (error.message) ? error.status_code : null
              this.showErrorModal(status_code);
            });
        }
      });
    },

    reproveCredit(id) {
      this.$swal.fire({
        title: this.$t('payments.creditRequest.modalConfirmationTitle'),
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
        text: this.$t('payments.creditRequest.modalReproveCreditText'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('payments.buttons.confirm'),
        cancelButtonText: this.$t('payments.buttons.cancel'),
        reverseButtons: true,
        input: 'text',
        inputValue: '',
        inputPlaceholder: this.$t('payments.creditRequest.modalReproveCreditInputPlaceholder'),
        inputValidator: value => !value && this.$t('payments.creditRequest.modalReproveCreditInputValidator'),
        inputAttributes: {
          maxlength: 250,
          autocomplete: 'off',
        },
      }).then(result => {
        if(!result || result.dismiss === 'cancel') return;

        if (result.value) {
          this.loader = true

          makeMutation(
            REPROVE_CREDIT,
            {
              id,
              reason: result.value,
              reproved_by: this.attributes.email,
            }
          )
          .then(() => {
            this.loader = false
            this.modalCreditRequest = false
            this.showSuccessModal();
            this.$refs.pending_credit_table.$_refresh();
            this.getPendingCount();
          })
          .catch(() => {
            this.loader = false
            this.showErrorModal();
          });
        }
      });
    },
    setPriceFormat(_currency, _value) {
      return formatPrice(_currency, _value);
    },
    goTo({ data }) {
      data.invoice = this.kovi_fatura + data.transaction
      this.modalData = data
      this.modalCreditRequest = true
    },
  },
};
</script>

<style scoped>
  .custom-data-table__pagination {
    margin-bottom: 40px;
  }
</style>
